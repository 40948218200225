import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';
import * as Sentry from "@sentry/browser";

export const useRegistrationStore = defineStore('registrationStore', {
    state: () => ({
        registrationData: JSON.parse(localStorage.getItem('registrationData') || 'null'),
    }),
    actions: {
        async checkRegistration(programId) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}registrations/create`, {
                    params: { program_id: programId },
                    headers: { Authorization: `Bearer ${userStore.accessToken}` },
                });
                if (response.data.error_code) {
                    let errorMessage = decodeURIComponent(response.data.message);
                    return { success: false, message: errorMessage };
                } else if (response.data.errors) {
                    return { success: false, message: 'Ошибка регистрации: ' + response.data.errors.join('; ') };
                } else if (response.data.data && response.data.data.program) {
                    this.setRegistrationData(response.data.data);
                    const extractedProgramId = response.data.data.program.id;
                    return { success: true, programId: extractedProgramId };
                } else {
                    return { success: false, message: 'Не удалось получить данные для регистрации.' };
                }
            } catch (error) {
                Sentry.captureException(error);
                return { success: false, message: 'Произошла ошибка при попытке регистрации. Пожалуйста, попробуйте позже.' };
                //
                // if (error.response) {
                //     // Сервер ответил с ошибочным статусом
                //     if (error.response.status === 401) {
                //         return { success: false, message: 'Ошибка авторизации. Пожалуйста, войдите снова.' };
                //     } else if (error.response.status === 500) {
                //         return { success: false, message: 'Внутренняя ошибка сервера. Пожалуйста, попробуйте позже.' };
                //     } else {
                //         return { success: false, message: `Ошибка: ${error.response.status}. Пожалуйста, попробуйте позже.` };
                //     }
                // } else if (error.request) {
                //     // Запрос был сделан, но ответа не было
                //     return { success: false, message: 'Нет ответа от сервера. Пожалуйста, проверьте ваше интернет-соединение.' };
                // } else {
                //     // Произошла ошибка при настройке запроса
                //     return { success: false, message: 'Ошибка при настройке запроса. Пожалуйста, попробуйте позже.' };
                // }
            } finally {
                loadingStore.stopLoading();
            }
        },
        setRegistrationData(data) {
            this.registrationData = data;
            localStorage.setItem('registrationData', JSON.stringify(data));
        },
        clearRegistrationData() {
            this.registrationData = null;
            localStorage.removeItem('registrationData');
        },
        setAchievementsData(achievementsData) {
            if (this.registrationData) {
                this.registrationData.achievementsData = achievementsData;
                localStorage.setItem('registrationData', JSON.stringify(this.registrationData));
            } else {
                console.error('Данные регистрации отсутствуют');
            }
        }
    }
});
