<template>
  <div v-if="useEmptyLayout">
    <router-view/>
  </div>
  <div v-else>
    <AppSpinner v-if="isLoading"/>
    <AppHeader/>
    <div className="content">
      <div className="left">
        <router-view/>
      </div>
      <div className="right">
        <AppPersonalMenu/>
      </div>
    </div>
    <AppMobileMenu/>
    <AppFooter/>
  </div>
</template>

<script>
import {computed} from 'vue';
import {useRoute} from 'vue-router';
import AppHeader from './components/common/AppHeader.vue';
import AppFooter from './components/common/AppFooter.vue';
import AppPersonalMenu from './components/common/AppPersonalMenu.vue';
import AppMobileMenu from './components/common/AppMobileMenu.vue';
import AppSpinner from './components/common/AppSpinner.vue';
import {useLoadingStore} from './stores/loadingStore';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    AppPersonalMenu,
    AppMobileMenu,
    AppSpinner
  },

  setup() {
    const route = useRoute();
    const loadingStore = useLoadingStore();
    const isLoading = computed(() => loadingStore.isLoading);
    const useEmptyLayout = computed(() => route.meta.layout === 'empty');

    return {
      isLoading,
      useEmptyLayout
    };
  }
};
</script>

