import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useProgramsStore = defineStore('programsStore', {
    state: () => ({
        programs: [],
        directions: [],
        subjects: [],
        programsForSubject: [],
    }),
    actions: {
        async fetchPrograms() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список программ без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}schedule/programs`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.programs = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка программ:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
        async fetchDirections() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список направлений без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}programs/directions`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.directions = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка направлений:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
        async fetchSubjectsForDirection(directionId) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список предметов без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}programs/subjects`, {
                    params: { direction_id: directionId },
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.subjects = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка тем:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
        async fetchProgramsForSubject(subjectId) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список программ для предмета без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}programs`, {
                    params: {
                        subject_id: Number(subjectId),
                        with_courses: 0,
                    },
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.programsForSubject = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка программ для темы:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
    },
});
