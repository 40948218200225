import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useScheduleStore = defineStore('scheduleStore', {
    state: () => ({
        schedule: [],
    }),
    actions: {
        async fetchSchedule(programId) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            loadingStore.startLoading();
            if (!userStore.accessToken) {
                console.warn('Попытка получить расписание без токена доступа');
                return;
            }
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}schedule/all?program_id=${programId}`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.schedule = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении расписания:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
    },
});
