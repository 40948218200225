import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';

export const useUserStore = defineStore('userStore', {
    state: () => ({
        userData:null,
        subjects: [],
        accessToken: localStorage.getItem('accessToken'),
        isAuthenticated: false,
        emailConfirmed: false,
        isFetchingUserData: false,
        isUserDataLoaded:false,
    }),
    getters: {
        apiBaseUrl: () => process.env.VUE_APP_API_URL,
        isProfileComplete(state) {
            return state.userData &&
                state.userData.merch_size &&
                state.userData.email &&
                state.userData.phone &&
                state.userData.relative &&
                state.userData.relative.last_name &&
                state.userData.relative.first_name &&
                state.userData.relative.middle_name &&
                state.userData.relative.phone &&
                state.userData.selected_subjects.length === 2;
        },
        getAccessToken:(state)=>state.accessToken,
    },
    actions: {
        async login(authCode) {
            try {
                const response = await axios.post(`${this.apiBaseUrl}auth/mesh`, {
                    code: authCode
                });
                if (response.data.data && response.data.data.user && response.data.data.token) {
                    this.userData = response.data.data.user;
                    this.accessToken = response.data.data.token;
                    localStorage.setItem('accessToken', this.accessToken);
                    this.isAuthenticated = true;
                    // console.log('Успешная авторизация:', this.userData, 'AccessToken:', this.accessToken);
                    await this.fetchUserData();
                } else {
                    console.error('Неправильный формат данных в ответе:', response.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    const errorMessage = error.response.data.message || 'Не все данные определены в ФГИС "Моя школа". Обратитесь к администрации Вашей образовательной организации.';
                    alert('Ошибка аутентификации: '+ errorMessage);
                    console.error('Ошибка аутентификации:', errorMessage);
                } else {
                    console.error('Ошибка аутентификации через Mesh:', error);
                }
                console.error('Ошибка аутентификации через Mesh:', error);
            }
        },
        async loginSP(authCode) {
            try {
                const response = await axios.post(`${this.apiBaseUrl}auth/sp`, { code: authCode });
                if (response.data && response.data.data) {
                    const { user, token } = response.data.data;
                    this.userData = user;
                    this.accessToken = token;
                    localStorage.setItem('accessToken', this.accessToken);
                    this.isAuthenticated = true;
                    // console.log('Успешная авторизация через SP:', this.userData, 'AccessToken:', this.accessToken);
                    await this.fetchUserData();
                } else {
                    console.error('Неправильный формат данных в ответе:', response.data);
                }
            } catch (error) {
                console.error('Ошибка аутентификации через SP:', error);
            }
        },
        async fetchUserData() {
            if (this.isFetchingUserData) {
                // Если запрос уже выполняется, не запускаем новый запрос
                return;
            }
            this.isFetchingUserData = true;
            this.isUserDataLoaded = false;
            const loadingStore = useLoadingStore();
            if (!this.accessToken) {
                console.warn('Попытка получить данные пользователя без токена');
                this.isFetchingUserData = false;
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${this.apiBaseUrl}users/me`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                this.userData = response.data.data;
                this.emailConfirmed = response.data.error_code !== 8;
                if (!this.userData.relative) {
                    this.userData.relative = {
                        last_name: '',
                        first_name: '',
                        middle_name: '',
                        phone: ''
                    };
                }
                this.isUserDataLoaded = true;
            } catch (error) {
                console.error('Ошибка при получении данных пользователя:', error);
                this.emailConfirmed = false;
            } finally {
                loadingStore.stopLoading();
                this.isFetchingUserData = false;
            }
        },
        async fetchSubjects() {
            if (!this.accessToken) {
                console.warn('Попытка получить список предметов без токена');
                return;
            }
            try {
                const response = await axios.get(`${this.apiBaseUrl}subjects`, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                this.subjects = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка предметов:', error);
            }
        },
        async fakeLogin(token) {
            this.accessToken = token;
            localStorage.setItem('accessToken', token);
            this.isAuthenticated = true;
            await this.fetchUserData();
        },
        logout() {
            this.accessToken = null;
            this.userData = null;
            this.subjects = [];
            if(localStorage.getItem('pendingProgramId')){
            localStorage.removeItem('pendingProgramId');
            }
            localStorage.removeItem('accessToken');
            this.isAuthenticated = false;
            localStorage.removeItem('registrationData');
            this.isUserDataLoaded = false;
        },
    }
});
