<template>
  <div>
    <div v-if="error">
      <p>Ошибка авторизации: {{ error }}</p>
    </div>
    <div v-else-if="loading">
      Обработка авторизации...
    </div>
    <div v-else>
      <p>Авторизация успешно выполнена. Перенаправление...</p>
    </div>
  </div>
</template>

<script>
import { useRoute,useRouter } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useRegistrationStore } from '@/stores/registrationStore';
import { ref,onMounted} from 'vue';
import * as Sentry from  "@sentry/browser";

export default {
  name: 'SPAuthHandler',

  setup() {
    const error = ref("");
    const loading = ref(true);
    // const timeoutId = ref(null);

    onMounted(async () => {
      const route = useRoute();
      const router = useRouter();
      const code = route.query.code;

      if (code) {
        const userStore = useUserStore();
        const registrationStore = useRegistrationStore();

        try {
          await userStore.loginSP(code);
          loading.value = false;
          const pendingProgramId = localStorage.getItem('pendingProgramId');

          if (pendingProgramId) {
            const result = await registrationStore.checkRegistration(pendingProgramId);

            if (result.success && result.programId) {
              router.push({name: 'RegistrationPage', params: {programId: result.programId}});
            } else {
              alert(result.message || 'Ошибка при регистрации. Попробуйте ещё раз.');
              router.push('/');
            }

          } else {
            router.push('/');
          }
        } catch (err) {
          loading.value = false;
          error.value = err.message || 'Не удалось обработать код авторизации';
          alert(error.value);
          router.push('/login');
          Sentry.captureException("Не удалось обработать код авторизации", err);
          // timeoutId.value = setTimeout(() => router.push('/login'), 3000);
        }
      } else {
        loading.value = false;
        error.value = 'Код авторизации не найден, возвращение на страницу логина';
        alert(error.value);
        router.push('/login')
        Sentry.captureMessage(`Ошибка авторизации через SP: ${error.value}`, 'error');
        // timeoutId.value = setTimeout(() => router.push('/login'), 3000);
      }
    });

    // onBeforeUnmount(() => {
    //   if (timeoutId.value) {
    //     clearTimeout(timeoutId.value);
    //   }
    // });

    return {error, loading};
  }
}
</script>
