<template>
  <div style="margin: 0 auto; font-size: 30px;">
    Страница ошибки
  </div>
</template>
<script>
import * as Sentry from "@sentry/browser";

export default {
  name: "ErrorPage",
  mounted() {
    const error = new Error("Переход на страницу ошибки");

    Sentry.captureException(error);
  }
};
</script>