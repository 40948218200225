<template>
  <div>
    <span class="h1">Регистрация <span class="red">на программы</span></span>
    <div class="registration-for-programs">
      <div v-for="direction in directions" :key="direction.id" class="direction-item"
           :style="`--direction-color: ${convertColor(direction.color)};`">
        <span class="name">{{ direction.title }}</span>
        <div class="desc">
          <div class="text">{{ direction.description }}</div>
          <div class="image">
            <img :src="require(`@/assets/images/${direction.icon}.png`)"/>
          </div>
        </div>
        <router-link
            :to="{
              name: 'Subjects',
              params: { directionId: direction.id },
              query: { directionTitle: direction.title }
            }"
            class="subject-selection">
          <span>Выбрать предмет</span>
          <span class="icon-arrow-right"></span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {useProgramsStore} from '@/stores/programsStore';

export default {
  name: "RegistrationForPrograms",

  computed: {
    directions() {
      const programsStore = useProgramsStore();
      return programsStore.directions;
    }
  },

  methods: {
    convertColor(color) {
      return `#${color.slice(4)}`;
    }
  },
}
</script>

<style scoped>
.direction-item {
  --direction-color: none;
}
.direction-item .subject-selection {
  background-color: var(--direction-color);
}
</style>


