import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useRegistrationsStore = defineStore('registrationsStore', {
    state: () => ({
        registrations: [],
    }),
    actions: {
        async fetchRegistrations() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список регистраций без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}registrations`, {
                    headers: { Authorization: `Bearer ${userStore.accessToken}` },
                });
                this.registrations = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка регистраций:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },

        async addRegistrations(formData) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка регистрации без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.post(`${userStore.apiBaseUrl}registrations`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${userStore.accessToken}`
                    }
                });

                if (response.status === 200 || response.status === 201 ) {
                    this.registrations.push(response.data.data);
                    return { success: true,data: response.data.data};
                } else {
                    console.error('Ошибка при отправке формы:', response);
                    alert('Ошибка при отправке формы: ' + (response.data.errors ? response.data.errors.join('; ') : response.statusText));
                }
            } catch (error) {
                console.error('Ошибка при попытке регистраций:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },

        async confirmArrival(registrationId, willArrive, reason = "") {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            loadingStore.startLoading();
            try {
                const body = { arrive_confirmation: willArrive, arrive_reason: reason };
                const response = await axios.post(`${userStore.apiBaseUrl}registrations/${registrationId}/confirm-arrival`, body, {
                    headers: { Authorization: `Bearer ${userStore.accessToken}` },
                });
                if (response.status === 200) {
                    this.fetchRegistrations();
                }
            } catch (error) {
                console.error('Ошибка при подтверждении прибытия:', error);
                alert('Ошибка при подтверждении прибытия: ' + (error.response?.data.message || error.message));
            } finally {
                loadingStore.stopLoading();
            }
        },

        async cancelRegistration(registrationId) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            loadingStore.startLoading();
            try {
                const response = await axios.delete(`${userStore.apiBaseUrl}registrations/${registrationId}`, {
                    headers: { Authorization: `Bearer ${userStore.accessToken}` },
                });
                if (response.status === 204) {
                    this.fetchRegistrations();
                }
            } catch (error) {
                console.error('Ошибка при отмене заявки:', error);
                alert('Ошибка при отмене заявки: ' + (error.response?.data.message || error.message));
            } finally {
                loadingStore.stopLoading();
            }
        }
    }
});
