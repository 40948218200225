import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useCoinsStore = defineStore('coinsStore', {
    state: () => ({
        balance: 0,
        transactions: [],
        error: null,
    }),
    actions: {
        async fetchBalance() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить баланс Vzlet Coins без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}coins/balance`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.balance = response.data.balance;
                this.error = null;
            } catch (error) {
                this.error = 'Произошла ошибка при получении баланса Vzlet Coins';
                console.error('Ошибка при получении баланса Vzlet Coins:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },

        async fetchTransactions() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить транзакции Vzlet Coins без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}coins/transactions`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.transactions = response.data.data;
                this.error = null;
            } catch (error) {
                this.error = 'Произошла ошибка при получении транзакций Vzlet Coins';
                console.error('Ошибка при получении транзакций Vzlet Coins:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
    },
});
