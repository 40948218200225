import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useProductsStore = defineStore('productsStore', {
    state: () => ({
        products: [],
        meta: null,
    }),
    actions: {
        async fetchProducts() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            loadingStore.startLoading();

            try {
                const response = await axios.get(`${userStore.apiBaseUrl}products`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    }
                });
                this.products = response.data.data;
                this.meta = response.data.meta;
            } catch (error) {
                console.error('Ошибка при получении списка товаров:', error);
            } finally {
                loadingStore.stopLoading();
            }
        }
    }
});
