<template>
  <div>

    <div class="with-bread-crumbs">
      <span class="h1">Расписание</span>
      <span class="sep">/</span>
      <router-link to="/">
        Программы
      </router-link>
    </div>

    <h2 v-if="selectedGroupSchedule">{{ selectedGroupSchedule.program.title }}</h2>

    <div class="select-div" v-if="schedule.length > 0">
      <span class="name">Выбор группы:</span>
      <div class="select" @click="toggleDropdown">
        <span class="selected">{{ selectedGroupName }}</span>
        <div class="options" v-if="showDropdown">
          <div
              class="option"
              v-for="groupSchedule in schedule"
              :key="groupSchedule.group.id"
              @click.stop="selectGroup(groupSchedule.group.id)"
          >
            {{ groupSchedule.group.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="day-line" v-if="selectedGroupSchedule">
      <div class="num-line schedule-num-line">
        <a href="javascript:void(0)"
           v-for="(day, index) in selectedGroupSchedule.items"
           :key="day.day"
           :class="['day-item', `day-item-${index}`, {'current': selectedDayIndex === index}]"
           @click="selectDay(index)">
          <span class="num">{{ index + 1 }}</span>
          <div class="date-line-item">
            <span class="date-desc">{{ formatDate(day.day, index).dayDescription }}</span>
            <span class="date">{{ formatDate(day.day, index).formattedDate }}</span>
          </div>
        </a>
      </div>
    </div>

    <div class="shedule" v-if="selectedDayEvents">
      <div v-for="event in selectedDayEvents" :key="event.id" class="shedule-item">
        <div class="time">{{ event.time_begin }} - {{ event.time_end }}</div>
        <div class="event">
          <span class="name">{{ event.title }}</span>
          <span v-if="!isOnlineProgram && event.location" class="place">
            &nbsp;({{ event.location }}<span
              v-if="event.education && event.education.classroom">, каб. {{ event.education.classroom }}</span>)
          </span>
          <span v-if="!isOnlineProgram" class="place">
            <span v-if="event.education && event.education.classroom">, (каб. {{ event.education.classroom }})
            </span>
          </span>
        </div>
        <div v-if="event.education" class="teach-in">
          <span class="name">{{ event.education.title }}</span>
          <span class="place">{{ event.education.type }}</span>
          <span class="teacher">{{ event.education.teacher_name }}</span>
          <div class="teach-in-links">
            <a v-if="isOnlineProgram && event.education.link_record" :href="event.education.link_record"
               class="link-with-icon dop-line" target="_blank"><span>Ссылка на запись вебинара</span></a>
            <a v-else-if="isOnlineProgram && event.education.classroom" :href="event.education.classroom"
               class="link-with-icon dop-line" target="_blank"><span>Ссылка на вебинар</span></a>
            <a v-if="event.education.link_material" :href="event.education.link_material"
               class="link-with-icon dop-line" target="_blank"><span>Ссылка на материалы</span></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {useScheduleStore} from '@/stores/scheduleStore';

export default {
  name: 'ProgramSchedule',

  props: {
    programId: Number,
  },

  data() {
    return {
      selectedGroupId: null,
      selectedDayIndex: 0,
      showDropdown: false,
    };
  },

  computed: {
    store() {
      return useScheduleStore();
    },

    schedule() {
      return this.store.schedule;
    },

    selectedGroupSchedule() {
      return this.schedule.find(s => s.group.id === this.selectedGroupId);
    },

    selectedDayEvents() {
      return this.selectedGroupSchedule ? this.selectedGroupSchedule.items[this.selectedDayIndex].items : null;
    },

    selectedGroupName() {
      const group = this.schedule.find(g => g.group.id === this.selectedGroupId);
      return group ? group.group.title : 'Группа не выбрана';
    },

    isOnlineProgram() {
      return this.selectedGroupSchedule && this.selectedGroupSchedule.program.online;
    },
  },

  mounted() {
    this.store.fetchSchedule(this.programId).then(() => {
      if (this.schedule.length > 0) {
        this.selectedGroupId = this.schedule[0].group.id;
        this.setInitialDay();
      }
    });
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    selectGroup(groupId) {
      this.selectedGroupId = groupId;
      this.showDropdown = false;
    },

    selectDay(index) {
      this.selectedDayIndex = index;
      this.scrollToSelectedDay(index);
    },

    scrollToSelectedDay(index) {
      this.$nextTick(() => {
        const selectedElement = this.$el.querySelector(`.day-item-${index}`);
        if (selectedElement) {
          selectedElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
      });
    },

    setInitialDay() {
      const todayStr = new Date().toISOString().slice(0, 10);
      const todayIndex = this.selectedGroupSchedule.items.findIndex(day => day.day.startsWith(todayStr));
      if (todayIndex !== -1) {
        this.selectDay(todayIndex);
      } else {
        this.selectDay(0);
      }
    },

    formatDate(dateStr, index) {
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      const date = new Date(dateStr);
      let dayDescription = `День ${index + 1}`;
      if (!this.isOnlineProgram) {
        if (index === 0) dayDescription = "Заезд";
        else if (index === this.selectedGroupSchedule.items.length - 1) dayDescription = "Отъезд";
      }
      return {
        dayDescription,
        formattedDate: `${date.getDate()} ${months[date.getMonth()]}`
      };
    },
  },
};
</script>

<style scoped>
.select {
  position: relative;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}

.selected {
  padding: 10px;
  background-color: #fff;
}
</style>


