<template>
  <div class="spinner-overlay">
    <img width="150" height="150"
         src="@/assets/images/preloader.gif" alt="Загрузка...">
  </div>
</template>

<script>
export default {
  name: 'AppSpinner'
}
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
