<template>
  <header>
    <div class="content">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="@/assets/images/LogoVzlet.svg"/>
        </router-link>
      </div>
      <!-- /.left -->
      <div class="right">
        <div v-if="userData && !isMyDataPage" class="welcome">
          <span class="fs">Добро пожаловать<br>в&nbsp;личный кабинет,</span>
          <span class="ms">Привет, </span>
          <span class="red">{{ userData.first_name }}!</span>
        </div>
        <!-- /.welcome -->
        <div v-if="isMyDataPage">
          <span class="h1 h1__my-page">Мои <span class="red">данные</span></span>
        </div>
        <div v-if="isMyDataPage" class="coins-div ms">
          <router-link to="/coins-history" class="coins-image">
            <img src="@/assets/images/coin.png">
            <span class="num">{{ balance }}</span>
          </router-link>
          <span>Vzlet&nbsp;Coins</span>
        </div>
        <!-- /.coins-div -->
        <router-link to="/my-data" class="avatar" v-if="userData && userData.photo">
          <img :src="userData.photo" alt="Avatar"/>
        </router-link>
        <router-link to="/my-data" class="avatar" v-else-if="userData">
          <img :src="getImage(userData.gender)" alt="Avatar"/>
        </router-link>

      </div>
      <!-- /.right -->
    </div>
    <!-- /.content -->
  </header>
</template>


<script>
import { useUserStore } from '@/stores/userStore';
import { useCoinsStore } from '@/stores/coinsStore';
import { watch,computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'AppHeader',

  setup() {
    const userStore = useUserStore();
    const coinsStore = useCoinsStore();
    const userData = computed(() => userStore.userData);
    const balance= computed(()=>coinsStore.balance);
    const isMyDataPage = computed(()=>route.path === '/my-data');
    const route = useRoute();

    watch(
        () => userStore.accessToken,
        (accessToken) => {
          if (accessToken) {
            userStore.fetchUserData();
            coinsStore.fetchBalance();
          }
        },
        { immediate: true }
    );

    return {
      userData,
      balance,
      isMyDataPage,
    };
  },

  methods: {
    getImage(gender) {
      return gender === 'Женский'
          ? require('@/assets/images/girl.png')
          : require('@/assets/images/boy.png');
    }
  }
};
</script>

<style scoped lang="scss">

.avatar {
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
}

.avatar img {
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.h1__my-page {
  margin: 0;

  @media (min-width: 768px) {
    display: none;
  }
}

</style>
