<template>
  <div class="mobile-menu ms">

    <router-link to="/" exact active-class="selected">
      <i class="icon-home"></i>
      <span>Главная</span>
    </router-link>

    <router-link to="/programs" active-class="selected">
      <i class="icon-edit"></i>
      <span>Программы</span>
    </router-link>

    <router-link to="/my-requests" active-class="selected">
      <i class="icon-requests"></i>
      <span>Заявки</span>
    </router-link>

  </div>
</template>

<script>
export default {
  name: 'AppMobileMenu'
}
</script>
