<template>
  <div>
    <ProgramsSchedule />
    <RegistrationForPrograms />
  </div>
</template>

<script>
import ProgramsSchedule from '@/components/ProgramsSchedule.vue';
import RegistrationForPrograms from '@/components/RegistrationForPrograms.vue';

export default {
  name: "HomePage",

  components: {
    ProgramsSchedule,
    RegistrationForPrograms
  }
}
</script>

<style scoped>

</style>