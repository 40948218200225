<template>
  <div>
    <span className="h1">За что можно получить <span className="red">Vzlet Coins?</span></span>
    <div className="coins-faq">
      <h2>Фиксированная оплата</h2>
      <div className="coins-history-list">
        <div className="ch-item">
          <div className="ch-item-line">
            <span className="name">Посещение пар</span>
            <span className="num">+2</span>
            <img src="@/assets/images/coin.png"/>
          </div>
        </div>
        <div className="ch-item">
          <div className="ch-item-line">
            <span className="name">Посещение внеакадемических занятий</span>
            <span className="num">+1</span>
            <img src="@/assets/images/coin.png"/>
          </div>
        </div>
      </div>
      <h2>Дополнительная оплата</h2>
      <div className="coins-history-list">
        <div className="ch-item">
          <div className="ch-item-line">
            <p className="name">3-топ рейтинга по итогам программы</p>
            <span className="num">до 10</span>
            <img src="@/assets/images/coin.png"/>
            <span className="subject-classes">* баллы выставляют руководители программ</span>
          </div>
        </div>
        <div className="ch-item">
          <div className="ch-item-line">
            <p className="name">Победа в соревнованиях</p>
            <span className="num">до 10</span>
            <img src="@/assets/images/coin.png"/>
            <span className="subject-classes">проводимых в рамках досуговой деятельности</span>
          </div>
        </div>
        <div className="ch-item">
          <div className="ch-item-line">
            <span className="name">За чистоту в номере</span>
            <span className="num">до 10</span>
            <img src="@/assets/images/coin.png"/>
          </div>
        </div>
        <div className="ch-item">
          <div className="ch-item-line">
            <span className="name">Посещение внеакадемических занятий</span>
            <span className="num">до 10</span>
            <img src="@/assets/images/coin.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoinsInfoPage'
};
</script>

<style>
</style>
