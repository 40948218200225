import { defineStore } from 'pinia';
import axios from 'axios';
import { useLoadingStore } from './loadingStore';
import { useUserStore } from './userStore';

export const useCertificatesStore = defineStore('certificatesStore', {
    state: () => ({
        certificates: [],
    }),
    actions: {
        async fetchCertificates() {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка получить список сертификатов без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}certificates`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                });
                this.certificates = response.data.data;
            } catch (error) {
                console.error('Ошибка при получении списка сертификатов:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },

        async downloadCertificate(id) {
            const loadingStore = useLoadingStore();
            const userStore = useUserStore();
            if (!userStore.accessToken) {
                console.warn('Попытка скачать сертификат без токена доступа');
                return;
            }
            loadingStore.startLoading();
            try {
                const response = await axios.get(`${userStore.apiBaseUrl}certificates/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userStore.accessToken}`,
                    },
                    responseType: 'blob',
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `certificate-${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } catch (error) {
                console.error('Ошибка при загрузке сертификата:', error);
            } finally {
                loadingStore.stopLoading();
            }
        },
    },
});
